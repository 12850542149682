import './index.scss';

import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { shuffle } from 'lodash';

import Layout from 'components/layouts/Standard';

import ProcessDoc1 from 'assets/index/process-doc-1.svg';
import ProcessDoc2 from 'assets/index/process-doc-2.svg';
import ProcessDoc3 from 'assets/index/process-doc-3.svg';
import ProcessDoc4 from 'assets/index/process-doc-4.svg';
import ProcessDoc5 from 'assets/index/process-doc-5.svg';
import ProcessDoc6 from 'assets/index/process-doc-6.svg';
import ProcessDoc7 from 'assets/index/process-doc-7.svg';
import ProcessDoc8 from 'assets/index/process-doc-8.svg';

interface Friend {
  slug: string;
  name: string;
  twitter_handle: string;
  last_fm_username: string;
  bio: string;
  title: string;
  status: 'full-time' | 'part-time';
}

const IndexPage: React.FC = () => {
  const {
    allFriendsYaml: { allFriends }
  } = useStaticQuery(
    graphql`
      query {
        allFriendsYaml {
          allFriends: nodes {
            slug
            name
            twitter_handle
            last_fm_username
            bio
            title
            status
          }
        }
      }
    `
  ) as {
    allFriendsYaml: {
      allFriends: Friend[];
    };
  };

  const [friends, setFriends] = useState<Friend[]>(allFriends);

  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    setFriends(
      shuffle(allFriends.filter((f) => f.status === 'full-time')).concat(
        allFriends.filter((f) => f.status === 'part-time')
      )
    );
  }, [allFriends]);

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (videoRef.current != null) {
        videoRef.current.play();
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Layout
      title="Friends of The Web"
      tagline="is a small digital agency in Baltimore, Maryland."
    >
      <div className="home-box-1">
        <div className="content">
          <h2>
            We build websites and mobile applications for organizations who are
            leaders in their fields.
          </h2>
          <hr className="wide" />
          <p>
            We pride ourselves on working with outstanding partners from a wide
            range of industries. From type designers and independent retailers
            to cultural institutions and research labs — we provide design and
            development services to help our clients realize their goals.
          </p>
          <div className="lists">
            <div className="list">
              <h3>Select Clients</h3>
              <ul>
                <li>
                  <a href="https://xoxofest.com/">XOXO</a>
                </li>
                <li>
                  <a href="https://frerejones.com">Frere-Jones Type</a>
                </li>
                <li>
                  <a href="http://figure53.com">Figure 53</a>
                </li>
                <li>
                  <a href="https://huckberry.com">Huckberry</a>
                </li>
                <li>
                  <a href="https://easel.gse.harvard.edu/">
                    Harvard&rsquo;s EASEL Lab
                  </a>
                </li>
              </ul>
            </div>
            <div className="list">
              <h3>Preferred Tools</h3>
              <ul>
                <li>
                  <a href="https://facebook.github.io/react/">React</a> +{' '}
                  <a href="https://facebook.github.io/react-native/">
                    React Native
                  </a>
                </li>
                <li>
                  <a href="https://graphql.org/">GraphQL</a>
                </li>
                <li>
                  <a href="http://rubyonrails.org">Ruby on Rails</a>
                </li>
                <li>
                  <a href="https://www.gatsbyjs.org/">Gatsby</a>
                </li>
                <li>
                  <a href="https://solidus.io">Solidus</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="box">
          <img src="images/index/workof.png" className="workof" />
          <video
            autoPlay
            className="frere-jones"
            loop
            muted
            playsInline
            preload="none"
            ref={videoRef as any}
          >
            <source src="/video/frerejones.mp4" type="video/mp4" />
          </video>
          <img src="images/index/huckberry.png" className="huckberry" />
        </div>
      </div>

      <div className="home-box-overlap" />

      <div className="home-box-2">
        <div className="content">
          <h2>
            We use design sprints to better understand the problems we&rsquo;re
            solving and communicate our client&rsquo;s ideas.
          </h2>
          <hr className="wide" />
          <p>
            Our design sprints allow us to test assumptions about a new product
            or feature without the expense of complete implementation. The
            resulting prototype can be used for presentations, user testing, or
            simply internal evaluation before embarking on an extensive project.
          </p>
        </div>
        <div className="box">
          <div className="process-doc-1">
            <ProcessDoc1 />
          </div>
          <div className="process-doc-2">
            <ProcessDoc2 />
          </div>
          <div className="mobile-screens">
            <div className="process-doc">
              <ProcessDoc3 />
            </div>
            <div className="process-doc">
              <ProcessDoc4 />
            </div>
            <div className="process-doc">
              <ProcessDoc5 />
            </div>
            <div className="process-doc">
              <ProcessDoc6 />
            </div>
            <div className="process-doc">
              <ProcessDoc7 />
            </div>
            <div className="process-doc">
              <ProcessDoc8 />
            </div>
          </div>
          <img
            src="images/index/sketchmarks/x1.png"
            className="sketchmark-x1"
          />
          <img
            src="images/index/sketchmarks/x2.png"
            className="sketchmark-x2"
          />
          <img
            src="images/index/sketchmarks/o1.png"
            className="sketchmark-o1"
          />
          <img
            src="images/index/sketchmarks/o2.png"
            className="sketchmark-o2"
          />
          <img
            src="images/index/sketchmarks/o3.png"
            className="sketchmark-o3"
          />
          <img
            src="images/index/sketchmarks/z1.png"
            className="sketchmark-z1"
          />
          <img
            src="images/index/sketchmarks/arrow1.png"
            className="sketchmark-arrow1"
          />
        </div>
      </div>

      <div className="home-box-3">
        <div className="content">
          <h2>
            We provide ongoing support and maintenance to keep our
            client&rsquo;s sites up and to improve them iteratively over time.
          </h2>
          <hr className="wide" />
          <p>
            We maintain sites for our clients by updating software and
            dependencies, monitoring usage and bug reports, assisting with
            customer support, and making technical recommendations for the
            future.
          </p>
        </div>
        <div className="box">
          <div className="calendar">
            <table>
              <tbody>
                <tr>
                  <td className="blank" />
                  <td className="blank" />
                  <td>
                    <img
                      src="images/index/sketchmarks/check1.png"
                      className="checkmark no-1"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check2.png"
                      className="checkmark no-2"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check3.png"
                      className="checkmark no-3"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check4.png"
                      className="checkmark no-4"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check5.png"
                      className="checkmark no-5"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="images/index/sketchmarks/check6.png"
                      className="checkmark no-6"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check7.png"
                      className="checkmark no-7"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check8.png"
                      className="checkmark no-8"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check9.png"
                      className="checkmark no-9"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check10.png"
                      className="checkmark no-10"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check11.png"
                      className="checkmark no-11"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check12.png"
                      className="checkmark no-12"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="images/index/sketchmarks/check13.png"
                      className="checkmark no-13"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check14.png"
                      className="checkmark no-14"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check15.png"
                      className="checkmark no-15"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check16.png"
                      className="checkmark no-16"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check17.png"
                      className="checkmark no-17"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check18.png"
                      className="checkmark no-18"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check19.png"
                      className="checkmark no-19"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="images/index/sketchmarks/check20.png"
                      className="checkmark no-20"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check21.png"
                      className="checkmark no-21"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check22.png"
                      className="checkmark no-22"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check23.png"
                      className="checkmark no-23"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check24.png"
                      className="checkmark no-24"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check25.png"
                      className="checkmark no-25"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check26.png"
                      className="checkmark no-26"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="images/index/sketchmarks/check27.png"
                      className="checkmark no-27"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check28.png"
                      className="checkmark no-28"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check29.png"
                      className="checkmark no-29"
                    />
                  </td>
                  <td>
                    <img
                      src="images/index/sketchmarks/check30.png"
                      className="checkmark no-30"
                    />
                  </td>
                  <td className="blank" />
                  <td className="blank" />
                  <td className="blank" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <hr className="home-hr" />

      <div className="about-section">
        <p className="about-content">
          <strong>
            Our interdisciplinary team works side-by-side every day in our
            Baltimore offices.
          </strong>{' '}
          We’re generalists with overlapping skill sets, including design,
          development, writing, type design, and podcasting.
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage;
